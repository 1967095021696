import { API } from 'aws-amplify'

export function getAccount(accountId) {
  return API.get('Api', `/account/${accountId}`)
}

export function updateAccount(accountId, data) {
  return API.post('Api', `/account/${accountId}`, {
    body: data,
  })
}

export function changePlan(accountId, planId) {
  return API.post('Api', `/account/${accountId}/changePlan`, {
    body: {
      planId,
    },
  })
}

export function getPlans() {
  return API.get('Api', `/billing/plans`)
}

export const initiateCheckout = data => {
  const result = API.post('Api', '/billing/checkout', {
    body: data,
  })

  return result
}

export const initiatePortalSession = data => {
  const result = API.post('Api', '/billing/session', {
    body: data,
  })

  return result
}

export const disableAccount = accountId => {
  return API.post('Api', `/account/${accountId}/disable`, {})
}

export const upgradeSubscription = data => {
  const result = API.post('Api', '/billing/upgrade', {
    body: data,
  })

  return result
}

export const getReferrer = () => {
  return null
}

export const triggerReferral = (email, data = {}) => {
  return Promise.reject(new Error('Could not trigger referral'))
}
