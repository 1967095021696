const actions = {
  SET_STATE: 'account/SET_STATE',
  GET_ACCOUNT: 'account/GET_ACCOUNT',
  GET_PLANS: 'account/GET_PLANS',
  UPGRADE_SUBSCRIPTION: 'account/UPGRADE_SUBSCRIPTION',
  CHANGE_PLAN: 'account/CHANGE_PLAN',
  DISABLE_ACCOUNT: 'account/DISABLE_ACCOUNT',
}

export default actions
