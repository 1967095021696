import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  //App
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/Dashboard')),
    exact: true,
  },
  {
    path: '/project/setup',
    Component: lazy(() => import('pages/Project/Setup')),
    exact: true,
  },
  {
    path: '/project/settings',
    Component: lazy(() => import('pages/Project/Settings')),
    exact: true,
  },
  {
    path: '/translations',
    Component: lazy(() => import('pages/Translations/List')),
    exact: true,
  },

  {
    path: '/glossary',
    Component: lazy(() => import('pages/Glossary')),
    exact: true,
  },


  {
    path: '/billing',
    Component: lazy(() => import('pages/Billing')),
    exact: true,
  },

  {
    path: '/billing/upgrade',
    Component: lazy(() => import('pages/Billing')),
    exact: true,
  },

  {
    path: '/services',
    Component: lazy(() => import('pages/Services')),
    exact: true,
  },

  {
    path: '/live/editor',
    Component: lazy(() => import('pages/LiveEditor')),
    exact: true,
  },

  {
    path: '/live/sync',
    Component: lazy(() => import('pages/LiveSync')),
    exact: true,
  },

  {
    path: '/user/profile',
    Component: lazy(() => import('pages/UserProfile')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route exact path="/login" render={() => <Redirect to="/auth/login" />} />
                    <Route exact path="/signup" render={() => <Redirect to="/auth/register" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
