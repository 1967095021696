

export const trackEvent = (name, config = {}) => {

    try {
        window.HelpCrunch('trackEvent', name);
        window.FS.event(name)

        ga('send', 'event', name, name)
    }catch(e) {
        console.error(e);
    }
    
};

export const trackPageView = (path) => {
    
    try {
        //Google
        ga('send', 'pageview', path);
    }catch(e) {
        console.error(e);
    }
};


export const trackSignUp = () => {
    try {
        qp('track', 'CompleteRegistration');
    }catch(e) {
        console.error(e);
    }
};

export const trackUserData = (customData) => {

    try {
        window.HelpCrunch('updateUserData', customData);
        window.FS.setUserVars(customData)
    }catch(e){
        console.error(e)
    }
}