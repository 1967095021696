import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const Footer = ({ app }) => {
  const date = new Date();
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          {app} © {date.getFullYear()}
        </p>
      </div>
    </div>
  )
}

Footer.propTypes = {
  app: PropTypes.string.isRequired
}

export default Footer
