import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar } from 'antd'
import { Link } from "react-router-dom";
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user, helpCenterUrl }) => {

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
      <span id="topBar.profileMenu.hello">Hello, </span>
        <strong>
          {user.name || user.email || 'Anonymous'}
        </strong>
      </Menu.Item>
      <Menu.Item>
        <Link to="/billing">
          <i className="fa fa-file"></i>
          <span className="pr-1"> Billing </span>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a href={helpCenterUrl} target="blank">
          <i className="fa fa-question-circle" />
          <span className="pe-1"> Help </span>
        </a>

      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} size="large" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
