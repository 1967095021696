import React from 'react'
import { connect } from 'react-redux'
//import { setCurrentProjectIdAction, createNew } from 'ducks/projects'
import { Menu, Dropdown, Button } from 'antd'

import { default as appActions } from 'redux/app/actions'
import { default as projectActions } from 'redux/project/actions'

const mapStateToProps = (state) => ({
  projects: state.project.list,
  currentProjectId: state.project.currentProjectId,
})

const CREATE_PROJECT_KEY = 'CreateProject';

const ProjectSelection = ({ projects, currentProjectId, dispatch }) => {

  const handleProjectClick = event => {
    try {
      const id = event.item.props.id

      if (id === CREATE_PROJECT_KEY){
        dispatch({
          type: appActions.REDIRECT,
          payload: {
            url : '/project/setup'
          }
        })
      }
      else {
        
        dispatch({
          type: projectActions.SWITCH_PROJECT,
          payload: {
            projectId : id
          }
        })

      }
    } catch (e) {}
  }


  let currentProject = projects[currentProjectId] || {}
    
  const menu = (
    <Menu onClick={handleProjectClick}>
      {Object.keys(projects).filter(id => id !== currentProjectId ).map((id, index) => {
        const project = projects[id]

        return (
          <Menu.Item key={index} id={project.projectId}>
            {/* <Icon type="user" /> */}
            {project.name}
          </Menu.Item>
        )
      })}

      <Menu.Item key={CREATE_PROJECT_KEY} id={CREATE_PROJECT_KEY}>
          <div className="pr-1 text-center"> 
            <i className="fa fa-plus"></i>
            <span className="pr-1">
              Create New Project
            </span>
          </div>
      </Menu.Item>
    </Menu>
  )

  return (
      <div style={{ width: '300px' }} className="projectSelector d-inline-block mr-4">
        <Dropdown overlay={menu}>
          <Button style={{ marginLeft: 8 }} size="large" block>
            {currentProject.name} <span className="float-right"><i className="fa fa-chevron-down"></i></span>
          </Button>
        </Dropdown>
      </div>
  )
}

export default connect(mapStateToProps)(ProjectSelection)
