import { all } from 'redux-saga/effects'
import app from './app/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import account from './account/sagas'
import project from './project/sagas'

export default function* rootSaga() {
  yield all([app(), user(), menu(), settings(), account(), project()])
}
