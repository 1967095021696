const getMenuData = () => {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fa fa-home',
      url: '/dashboard',
      content: 'Get an overview of your account usage such as the plan, word usage and more.',
    },

    {
      title: 'Translations',
      key: 'translations',
      icon: 'fa fa-database',
      url: '/translations',
      content: 'Find all your translations and you can also manually edit them.',
    },

    {
      title: 'Glossary',
      key: 'glossary',
      icon: 'fa fa-wrench',
      url: '/glossary',
      content: 'Provide custom translations to use or words that should never be translated.',
    },

    {
      title: 'Live Editor',
      key: 'live-editor',
      icon: 'fa fa-edit',
      url: '/live/editor',
      content: 'Translate content directly on your website. Get the full context of the text you\'re translating.',
    },

    {
      title: 'Services',
      key: 'services',
      icon: 'fa fa-globe',
      url: '/services',
      content: 'Order professional translations or proofreading.',
    },

    {
      title: 'Settings',
      key: 'settings',
      icon: 'fa fa-cogs',
      url: '/project/settings',
      content: 'Add more languages, customize the language switcher and more. Find instructions on how to configure your website.',
    }
  ]
}

export default getMenuData