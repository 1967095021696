const actions = {
  SET_STATE: 'app/SET_STATE',
  SHOW_TOUR: 'app/SHOW_TOUR',
  HAS_SEEN_TOUR: 'app/HAS_SEEN_TOUR',
  REDIRECT: 'app/REDIRECT',
  READY: 'app/READY',
  START_LOADER: 'app/START_LOADER',
  STOP_LOADER: 'app/STOP_LOADER',
}

export default actions
