import { all, put, call, select, takeEvery, take } from 'redux-saga/effects'

import { getAccount, changePlan, getPlans, upgradeSubscription, disableAccount } from 'services/account/index'
import { successNotification, errorNotification, padNumber } from 'services/utils'
import { trackUserData } from 'services/events'
import actions from './actions'
import appActions from 'redux/app/actions'
import projectActions from 'redux/project/actions'
import userActions from 'redux/user/actions'

export function* GET_ACCOUNT() {
  const { accountId } = yield select(state => state.user)
  const { data } = yield call(getAccount, accountId)

  yield put({
    type: 'account/SET_STATE',
    payload: {
      ...data,
    },
  })

  yield put({
    type: 'project/GET_PROJECTS',
  })

  try {

    const { id, type, trial, createAt } = data

    let createdDate = new Date(createAt * 1000)

    // Convert to date and pass to helpcrunch
    let registrationDate = createdDate.getFullYear() + "-" + padNumber(createdDate.getMonth() + 1) + "-" + padNumber(createdDate.getDate()) + " " + createdDate.toLocaleTimeString()

    const customData = {
        accountId : id,
        accountType : type,
        isTrialAccount : trial,
        registrationDate : registrationDate
    }

    trackUserData(customData)

  }catch(e) {
    console.error(e)
  }

  yield take(projectActions.PROJECTS_LOADED)

  yield put({
    type: appActions.READY,
  })

  yield put({
    type: 'account/GET_PLANS',
  })
}

export function* GET_PLANS() {

  try {
    const { data } = yield call(getPlans)

    yield put({
      type: 'account/SET_STATE',
      payload: {
        plans: data,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export function* CHANGE_PLAN({ payload }) {
  const { accountId } = yield select(state => state.user)
  const { planId } = payload

  try {
    const { data } = yield call(changePlan, accountId, planId)

    yield put({
      type: 'account/SET_STATE',
      payload: {
        ...data,
      },
    })

    successNotification({ description: 'Plan changed!' })
  } catch (e) {
    errorNotification()
  }
}

export function* UPGRADE_SUBSCRIPTION({ payload }) {
  const { accountId } = yield select(state => state.user)
  const { subscriptionData, selectedPlan } = payload

  try {
    yield call(upgradeSubscription, subscriptionData)

    yield put({
      type: actions.CHANGE_PLAN,
      payload: {
        planId: selectedPlan,
      },
    })
  } catch (e) {
    console.error(e)
    errorNotification()
  }
}

export function* DISABLE_ACCOUNT() {
  const { accountId } = yield select(state => state.user)

  try {
    const { data } = yield call(disableAccount, accountId)

    yield put({
      type: 'account/SET_STATE',
      payload: {
        ...data,
      },
    })

    yield put({
      type: userActions.LOGOUT
    })

  } catch (e) {
    errorNotification()
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ACCOUNT, GET_ACCOUNT),
    takeEvery(actions.GET_PLANS, GET_PLANS),
    takeEvery(actions.CHANGE_PLAN, CHANGE_PLAN),
    takeEvery(actions.UPGRADE_SUBSCRIPTION, UPGRADE_SUBSCRIPTION),
    takeEvery(actions.DISABLE_ACCOUNT, DISABLE_ACCOUNT),
  ])
}
