import { all, put, call, select, take, takeEvery } from 'redux-saga/effects'
import {
  getProjects,
  createProject,
  updateProject,
  verifyDnsConfiguration,
} from 'services/project/index'
import { successNotification, errorNotification } from 'services/utils'
import actions from './actions'

export function* GET_PROJECTS() {
  const { accountId } = yield select(state => state.user)
  const { data } = yield call(getProjects, accountId)

  let defaultCurrentProjectId = window.localStorage.getItem('currentProjectId') || ''

  //Get all the unique projectIds, we will use them to see if the default current projectid is valid or not
  const allProjectIds = data.map(project => project.projectId)

  if (allProjectIds.length > 0) {
    const projects = data.reduce((final, project) => {
      if (!defaultCurrentProjectId || !allProjectIds.includes(defaultCurrentProjectId))
        defaultCurrentProjectId = project.projectId

      final[project.projectId] = project

      return final
    }, {})

    yield put({
      type: 'project/SET_STATE',
      payload: {
        currentProjectId: defaultCurrentProjectId,
        list: projects,
      },
    })
  } else {

      yield put({
        type: 'app/SHOW_TOUR',
      })
  }

  yield put({
    type: actions.PROJECTS_LOADED,
  })
}

export function* CREATE_PROJECT({ payload }) {
  const { id: accountId } = yield select(state => state.account)
  const { list } = yield select(state => state.project)

  const { project } = payload

  const { data } = yield call(createProject, accountId, project)

  list[data.projectId] = data

  yield put({
    type: actions.SET_STATE,
    payload: {
      createdProjectId: data.projectId,
      currentProjectId: data.projectId,
      list,
    },
  })
}

export function* UPDATE_CURRENT_PROJECT({ payload }) {
  const { currentProjectId, list } = yield select(state => state.project)

  const { project } = payload

  try {
    const { data } = yield call(updateProject, currentProjectId, project)

    list[data.projectId] = data

    yield put({
      type: actions.SET_STATE,
      payload: {
        currentProjectId: data.projectId,
        list,
      },
    })

    successNotification({ description: 'Settings updated!' })
  } catch (e) {
    console.error(e)
    errorNotification()
  }
}

export function* SWITCH_PROJECT({ payload }) {
  const { projectId } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      currentProjectId: projectId,
    },
  })
}

export function* VERIFY_DNS({ payload }) {
  const { currentProjectId, list } = yield select(state => state.project)

  try {
    const { data } = yield call(verifyDnsConfiguration, currentProjectId)

    list[data.projectId] = data

    yield put({
      type: actions.SET_STATE,
      payload: {
        currentProjectId: data.projectId,
        list,
      },
    })
    
    successNotification({ message : 'DNS Verification successful!'})
  }catch(e) {
    console.error(e)
    errorNotification()
  }
  
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PROJECTS, GET_PROJECTS),
    takeEvery(actions.CREATE_PROJECT, CREATE_PROJECT),
    takeEvery(actions.UPDATE_CURRENT_PROJECT, UPDATE_CURRENT_PROJECT),
    takeEvery(actions.SWITCH_PROJECT, SWITCH_PROJECT),
    takeEvery(actions.VERIFY_DNS, VERIFY_DNS),
    //GET_PROJECTS()
  ])
}
