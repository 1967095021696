/* global AppConfig */

import * as Sentry from '@sentry/react'

import Amplify, { Auth } from 'aws-amplify'
import { isDev } from 'services/utils'
import awsconfig from './aws-exports';

export const currentAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser()
}

const configureAmplify = () => {

    Amplify.configure(awsconfig);

    Amplify.configure({
        // Configure the api endpoint
        API: {
          endpoints: [
            {
              name: 'Api',
              endpoint: AppConfig.client.apiUrl + '/v1',
              custom_header: async () => {
                return {
                  'content-type': 'application/json',
                  'x-access-token': (await Auth.currentSession()).getIdToken().getJwtToken(),
                }
              },
            },
            {
              name: 'Auth',
              endpoint: AppConfig.client.apiUrl + '/v1/auth',
            },
          ],
        },
      })
    
};

export const configureFullstory = (userId, email) => {
    if(typeof window.FS === 'function') {
        window.FS.identify(userId, {
          email: email
        });
      }
}

export const configureHelpcrunch = (userId, email) => {
    window.HelpCrunch('init', AppConfig.common.helpcrunchApp, {
        applicationId: 1,
        applicationSecret: 'ZtloHguN7Mk3rncvUp0ldDKROlMgYvis8BwQVnF2ogQ/b5Jk0/Wm8MG9nMX8JtpHACh2hDPDnQ2e0XO2cmFpIQ==',
        user: {
          email,
          user_id: userId
        }
      });
  
    window.HelpCrunch('showChatWidget');
}

const configureGa = () => {

  ga('create', AppConfig.common.ga.trackingId, 'auto');
  ga('send', 'pageview');

}

const configure = async () => {
    if(!isDev()) {
        Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
    }

  configureAmplify();
  
  let email = '';

  try {

    const user = await currentAuthenticatedUser();

    email = user.username;
  }catch(e) {}

  configureGa()

}

export default configure
