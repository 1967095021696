import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from './app/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import account from './account/reducers'
import project from './project/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    app,
    user,
    menu,
    settings,
    account,
    project,
  })
