const actions = {
  SET_STATE: 'project/SET_STATE',
  GET_PROJECTS: 'project/GET_PROJECTS',
  CREATE_PROJECT: 'project/CREATE_PROJECT',
  PROJECT_CREATED: 'project/PROJECT_CREATED',
  UPDATE_CURRENT_PROJECT: 'project/UPDATE_CURRENT_PROJECT',
  PROJECT_UPDATED: 'project/PROJECT_UPDATED',
  SWITCH_PROJECT: 'project/SWITCH_PROJECT',
  VERIFY_DNS: 'project/VERIFY_DNS',
  USE_LIVE_EDITOR: 'project/USE_LIVE_EDITOR',
  PROJECTS_LOADED: 'project/PROJECTS_LOADED'
}

export default actions
