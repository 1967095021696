import { all, takeEvery, put, call, select, take } from 'redux-saga/effects'
import { history } from 'index'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import { trackSignUp } from 'services/events'
import { configureFullstory, configureHelpcrunch } from 'configure'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
    forgotPassword: jwt.forgotPassword,
    verifyCodeAndChangePassword: jwt.verifyCodeAndChangePassword,
  },
}

export function* RESET_AUTH_ERROR() {
  yield put({
    type: 'app/SET_STATE',
    payload: {
      authError: null,
    },
  })
}

export function* LOGIN({ payload }) {
  const { email, password, redirect } = payload

  yield RESET_AUTH_ERROR()

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider: autProviderName } = yield select(state => state.settings)

  try {
    yield call(mapAuthProviders[autProviderName].login, email, password)

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield take('user/CURRENT_ACCOUNT_LOADED')
    yield history.push(redirect ?? '/')
  } catch (e) {
    console.error(e)
    yield put({
      type: 'app/SET_STATE',
      payload: {
        authError: e.message,
      },
    })
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload

  yield RESET_AUTH_ERROR()

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider } = yield select(state => state.settings)

  try {
    yield call(mapAuthProviders[authProvider].register, email, password, name)

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield take('account/GET_ACCOUNT')

    yield history.push('/')

    trackSignUp()
  } catch (e) {
    yield put({
      type: 'app/SET_STATE',
      payload: {
        authError: e.message,
      },
    })
  } finally {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, email, name, avatar, role, accountId } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
        accountId,
      },
    })

    yield put({
      type: 'user/CURRENT_ACCOUNT_LOADED',
    })

    yield put({
      type: 'account/GET_ACCOUNT',
    })

    configureFullstory(id, email)
    configureHelpcrunch(id, email)
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      accountId: '',
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      email,
      loading: true,
    },
  })

  const { authProvider } = yield select(state => state.settings)

  try {
    yield call(mapAuthProviders[authProvider].forgotPassword, email)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        isResetingPassword: true,
      },
    })
  } catch (e) {
    console.error(e)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* VERIFY_CODE_AND_CHANGE_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { email } = yield select(state => state.user)

  const { code, password } = payload

  const { authProvider } = yield select(state => state.settings)

  try {
    yield call(mapAuthProviders[authProvider].verifyCodeAndChangePassword, email, code, password)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        isResetingPassword: false,
      },
    })

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield take('account/GET_ACCOUNT')

    yield history.push('/')
  } catch (e) {
    console.error(e)
    yield put({
      type: 'app/SET_STATE',
      payload: {
        forgotPasswordError: e.message,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.VERIFY_CODE_AND_CHANGE_PASSWORD, VERIFY_CODE_AND_CHANGE_PASSWORD),
    takeEvery(actions.RESET_AUTH_ERROR, RESET_AUTH_ERROR),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
