import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import getMenuData from 'services/menu/'
import { Modal, Button } from 'antd';
import actions from 'redux/app/actions'

const menuData = getMenuData();

let steps = menuData.map(item => {
  item.target = '#menu-item-' + item.key
  return item
})

menuData[0]['disableBeacon'] = true

const mapStateToProps = (state, props) => ({
})

@connect(mapStateToProps)
class TourApp extends React.Component {
  state = {
    steps: steps,
    showModal : true,
    showTour: false
  };

  helpers = {}

  handleTourFinish() {

    const { dispatch } = this.props

    dispatch({
      type: actions.HAS_SEEN_TOUR,
    })

  }

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if(ACTIONS.CLOSE == action){

      this.handleTourFinish()

      this.setState({
        showTour: false
      })
    }

    if(STATUS.FINISHED == status) {
      this.handleTourFinish()
    }
  };

  handleClose = e => {
    e.preventDefault();

    this.setState({
      showModal: false,
      showTour : true
    });
  }

  getHelpers = (helpers) => {
    this.helpers = helpers
  }

  render () {
    const { steps } = this.state;
    const { app } = this.props;

    return (
      <div className="app">
        <div style={{"zIndex" : "10000"}}>

        </div>
        {this.state.showModal &&
          <Modal
            visible={this.state.showModal}
            centered
            closable={false}
            footer={null}
            afterClose={this.handleClose}
            zIndex="10000"
          >
            <div style={{"textAlign" : "center"}}>
              <h3>Welcome to {app} 👋</h3>
              <div style={{"padding": "20px 5px"}}>
                <p>Let's do a quick tour and then we will setup {app} on your site</p>
              </div>

              <Button type="primary" size="large" onClick={this.handleClose}>Let's start</Button>
            </div>

          </Modal>
        }

        <Joyride
          scrollToFirstStep={true}
          run={this.state.showTour}
          continuous={true}
          steps={steps}
          callback={this.handleJoyrideCallback}
          getHelpers={this.getHelpers}

          styles={{
            options: {
              primaryColor: '#0190fe',
              zIndex: 10000,
            }
          }}
        />
      </div>
    );
  }
}

TourApp.propTypes = {
  app: PropTypes.string.isRequired
}

export default TourApp