import { notification } from 'antd'

export const successNotification = params => {
  notification.success({
    ...{
      message: 'Success',
    },
    ...params,
  })
}


export const errorNotification = params => {
  notification.error({
    ...{
      message: 'Error',
      description: 'An error occured. Please try again later',
    },
    ...params,
  })
}

export const reduxUpdateObjectInArray = (array, index, item) => {
  return array.map((arrayItem, arrayIndex) => {
    if (arrayIndex !== index) {
      // This isn't the item we care about - keep it as-is
      return arrayItem
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...arrayItem,
      ...item,
    }
  })
}

export const timeDifference = (previous, current) => {
  if (!previous) return 'n/a'

  if (!current) current = Math.floor(Date.now() / 1000)

  previous = new Date(previous * 1000)
  current = new Date(current * 1000)

  var msPerMinute = 60 * 1000
  var msPerHour = msPerMinute * 60
  var msPerDay = msPerHour * 24
  var msPerMonth = msPerDay * 30
  var msPerYear = msPerDay * 365

  var elapsed = current - previous

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago'
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago'
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago'
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago'
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago'
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago'
  }
}

export const isDev = () => {
  return process.env.NODE_ENV == "development";
}

export const padNumber = (number) => {
  return number < 10 ? '0' + number : number;
}