import { API } from 'aws-amplify'

export const createProject = (accountId, data) => {
  const body = {
    body: data,
  }

  return API.post('Api', `/project/${accountId}`, body)
}

export const updateProject = (projectId, data) => {
  const body = {
    body: data,
  }

  return API.put('Api', `/project/${projectId}`, body)
}

export const getProjects = id => {
  return API.get('Api', `/account/${id}/projects`)
}

export const verifyDnsConfiguration = projectId => {
  return API.post('Api', `/project/${projectId}/verifyDns`, {})
}
