import actions from './actions'

const initialState = {
    id: '',
    type: '',
    translationsCount: 0,
    translatedWordsCount: 0,
    translatedPageCount: 0,
    trial: false,
    projectsCountLimit: 1,
    translatedLanguageLimit: 1,
    translatedWordsCountLimit : 0,
    projects: [],
    createAt : 0,
    disabled : 0,
    subscriptionId : "",
    stats : {},
    plans: []
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
