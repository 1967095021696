import {  all, put, call, select, takeEvery } from 'redux-saga/effects'
import { history } from 'index'
import actions from './actions'

const showTourKey = 'app.Tour';

export function* SHOW_TOUR() {
  const seenTour = window.localStorage.getItem(showTourKey)

  if (seenTour == null || !seenTour || seenTour == undefined) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showTour: true
      }
    })
  }
}

export function* HAS_SEEN_TOUR() {
  
  yield put({
    type: actions.SET_STATE,
    payload: {
      showTour: false
    }
  })

  window.localStorage.setItem(showTourKey, true)

  yield put({
    type: actions.REDIRECT,
    payload: {
        url: '/project/setup'
    }
  })

}

export function* REDIRECT({ payload }) {
  const { url } = payload
  
  yield history.push(url)

}

export function* READY() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      ready: true
    }
  })
}


export function* START_LOADER({ payload }) {
  const { loader } = yield select(state => state.app)

  const { action } = payload
  
  yield put({
    type: actions.SET_STATE,
    payload: {
      loader: {
        ...loader,
        actions: [...loader.actions, action]
      }
    }
  })

}

export function* STOP_LOADER({ payload }) {
  const { loader } = yield select(state => state.app)
  //const { actions } = loader
  const { action } = payload
  
  yield put({
    type: actions.SET_STATE,
    payload: {
      loader: {
        ...loader,
        actions: loader.actions.filter(loaderAction => loaderAction !== action)
      }
    }
  })

}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SHOW_TOUR, SHOW_TOUR),
    takeEvery(actions.HAS_SEEN_TOUR, HAS_SEEN_TOUR),
    takeEvery(actions.REDIRECT, REDIRECT),
    takeEvery(actions.READY, READY),
    takeEvery(actions.START_LOADER, START_LOADER),
    takeEvery(actions.STOP_LOADER, STOP_LOADER),
  ])

}
