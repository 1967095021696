import store from 'store'
import { Auth, API } from 'aws-amplify';

const getTokens = user => {
  const userSession = user.signInUserSession;
  const accessToken = userSession.accessToken.jwtToken;
  const idToken = userSession.idToken.jwtToken;

  return { accessToken, idToken }
};

export async function login(email, password) {

    const user = await Auth.signIn(email, password)

    const { accessToken } = getTokens(user);

    if (accessToken) {
      store.set('accessToken', accessToken)
    }

    return user;
}

export async function register(email, password) {
    const user = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
      clientMetadata: {
        'confirm': "true"
      }
    });

    const { userSub } = user;
    
    const data = {
      username: email,
      userId: userSub
    }

    await API.post('Auth', '/verifySignup', {
      body: data,
    })

    return login(email, password);

}

export async function currentAccount() {
  return Auth.currentAuthenticatedUser({ bypassCache: true })
  .then(user => {
    const { accessToken } = getTokens(user);

    if (accessToken) {
      store.set('accessToken', accessToken)
    }

    const data = {
        email: user.username,
        id: user.attributes.sub,
        role: 'admin',
        name: '',
        accountId: user.attributes['custom:accountId'] || ''
    };

    return data;

  })
  .catch(err => console.log(err))
}


export async function logout() {
  return Auth.signOut()
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function forgotPassword(email) {

  await Auth.forgotPassword(email)

  return true;
}

export async function verifyCodeAndChangePassword(email, code, password) {

  await Auth.forgotPasswordSubmit(email, code, password)

  return login(email, password);
}