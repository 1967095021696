import actions from './actions'

const initialState = {
    showTour: false,
    authError: null,
    forgotPasswordError: null,
    ready: false,
    loader: {
      actions: []
    }
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
